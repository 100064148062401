@use "./utils/helpers";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/*@import-normalize;*/

@include helpers.make-helpers();
@include helpers.make-static-helpers();

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

h1,h2,h3,h4,p, h5, h6{
  margin: 0;
}

a{
  text-decoration: none;
}

