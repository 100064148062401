$width--desktop: 1920px;
$width--laptop-XL: 1720px;
$width--laptop-Ios: 1680px;
$width--laptop-X: 1366px;
$width--laptop: 1256px;
$width--laptop-wrap: 1000px;
$width--tablet: 999px;
$width--phone-l: 768px;
$width--phone-s: 565px;
$width--phone-sm: 425px;
$width--phone: 0;

$bp-360: bp360;
$bp-768: bp768;
$bp-1024: bp1024;
$bp-1256: bp1256;
$bp-1342: bp1342;
$bp-1920: bp1920;
$breakpoints: (
  bp360: 0,
  bp768: 700px,
  bp1024: 1000px,
  bp1256: 1200px,
  bp1342: 1300px,
  bp1920: 1600px,
);

// @deprecated
@mixin media--max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// @deprecated
@mixin media--min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}


@function breakpoint-min($name, $breakpoints: $breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-prev($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n > 1, nth($breakpoint-names, $n - 1), null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}


@mixin media-breakpoint-up($bp, $breakpoints: $breakpoints) {
  $min: breakpoint-min($bp, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}

@mixin breakpoint-up($bp) {
  @include media-breakpoint-up($bp) {
    @content
  }
}

@mixin breakpoint-down($bp) {
  @include media-breakpoint-down($bp) {
    @content
  }
}

@mixin breakpoint-only($bp) {
  @include media-breakpoint-only($bp) {
    @content
  }
}
