@use "breakpoints";
@use "./variables" as variables;
@use "sass:map";
@use "sass:math";

$displays: (
    none inline flex block inline-block inline-flex grid contents
);
$positions: (
    static fixed absolute sticky relative
);
$textAlignments: (
    left center right
);
$flexAlignItems: (
    initial start end center baseline
);
$flexWrap: (
    wrap nowrap
);
$flexDirections: (
    row row-reverse column column-reverse
);
$flexJustifyContent: (
    initial: initial,
    start: start,
    end: end,
    center: center,
    between: space-between,
    even: space-evenly,
    around: space-around,
);
$flexGrow: (
    0: 0,
    1: 1,
);
$flexBasis: (
    0: 0px,
);

$margins: (
    auto: auto,
    0: 0,
    2: 2px,
    6: 6px,
    12: 12px,
    16: 16px,
    20: 20px,
    24: 24px,
    28: 28px,
    32: 32px,
    44: 44px,
);

$overflows: (
    auto: auto,
    scroll: scroll,
    hidden: hidden,
);

$zIndexes: (
    sidebar: 1,
    content: 1111,
    bottombar: 2222,
    modal: 3333,
    dropdown: 4444,
    tom: 999999999,
);

$textColors: map-keys(variables.$colors);
$textSizes: (
    h1: normal 700 40px Roboto,
    h2: normal 700 32px Roboto,
    t1: normal 700 16px Roboto,
    t2: normal 400 16px Roboto,
    t3: normal 700 14px Roboto,
    t4: normal 400 14px Roboto,
    t5: normal 700 12px Roboto,
    t6: normal 400 12px Roboto,
);


@function color-name-to-css-variable($name) {
    @return --#{"" + $name};
}

@mixin make-static-helpers {
    .bg-white {
        background: white;
    }
    .h-100 {
        height: 100%;
    }
    .w-100 {
        width: 100%;
    }
    .minw-100 {
        min-width: 100%;
    }
    .cursor {
        &-pointer {
            cursor: pointer;
        }
    }
    .user-select-none {
        user-select: none;
    }
    .image-center {
        object-position: center;
        object-fit: contain;
    }

    .flex-center {
        align-items: center;
        justify-content: center;
    }

    .click {
        &-through {
            pointer-events: none;
        }

        &-all {
            pointer-events: all;
        }
    }
    .text {
        &-prewrap {
            white-space: pre-wrap;
        }

        @each $cl in $textColors {
            &-#{$cl} {
                color: var(#{color-name-to-css-variable($cl)})
            }
        }

        &-underline {
            text-decoration: underline;
        }

        &-decoration-none {
          text-decoration: none;
        }
    }
    @each $z, $zValue in $zIndexes {
        .z {
            &-#{$z} {
                z-index: $zValue;
            }
        }
    }

    .opacity {
        @each $op in (0 25 50 75 1) {
            &-#{$op} {
                opacity: math.div($op, 100);
            }
        }
    }
    .hidden {
        visibility: hidden;
    }

    .circle {
        border-radius: 50%;
    }

    .scroll-hide {
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .inset-0 {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .transparent {
        opacity: 0;
    }
    .bordered {
        position: relative;

        &:before {
            content: '';
            pointer-events: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            border-radius: 24px;
            border: 1px solid var(--greyscale-grey-300, #E9EBF2);
            z-index: 1;
        }
    }
}

@mixin make-helper($values, $helperPrefix, $propName) {
    @each $bp, $_ in breakpoints.$breakpoints {
        @include breakpoints.breakpoint-up($bp) {
            @each $helperSuffix, $helperValue in $values {
                @if $helperValue == null {
                    $helperValue: $helperSuffix;
                }
                @if breakpoints.breakpoint-min($bp) {
                    .#{$helperPrefix}-#{$bp}-#{$helperSuffix} {
                        #{$propName}: #{$helperValue} !important;
                    }
                } @else {
                    .#{$helperPrefix}-#{$helperSuffix} {
                        #{$propName}: #{$helperValue} !important;
                    }
                }
            }
        }
    }
}

@mixin make-helpers {
    @include make-helper($displays, d, display);
    @include make-helper($positions, p, position);
    @include make-helper($textAlignments, text, text-align);
    @include make-helper($flexWrap, flex, flex-wrap);
    @include make-helper($flexDirections, flex, flex-direction);
    @include make-helper($flexAlignItems, align-items, align-items);
    @include make-helper($flexJustifyContent, justify-content, justify-content);
    @include make-helper($flexGrow, flex-grow, flex-grow);
    @include make-helper($flexGrow, flex-shrink, flex-shrink);
    @include make-helper($flexBasis, flex-basis, flex-basis);

    @include make-helper($margins, mt, margin-top);
    @include make-helper($margins, mb, margin-bottom);
    @include make-helper($margins, ml, margin-left);
    @include make-helper($margins, mr, margin-right);
    @include make-helper($margins, m, margin);
    @include make-helper($margins, pt, padding-top);
    @include make-helper($margins, pb, padding-bottom);
    @include make-helper($margins, pl, padding-left);
    @include make-helper($margins, pr, padding-right);
    @include make-helper($margins, p, padding);

    @include make-helper($margins, gap, gap);
    @include make-helper($margins, row-gap, row-gap);

    @include make-helper($overflows, overflow, overflow);
    @include make-helper($overflows, overflow-x, overflow-x);
    @include make-helper($overflows, overflow-y, overflow-y);
    @include make-helper($textSizes, text, font);

    @include make-static-helpers;
}

