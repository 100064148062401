.navTabs {
  font-size: 16px;
  line-height: 150%;
  border: 1px solid var(--grey-200);
  overflow: hidden;
  border-radius: 8px;
  user-select: none;
}

.navTab {
  text-decoration: none;
  color: var(--grey-100);
  border-right: 1px solid var(--grey-200);
  padding: 7px 0;

  &:last-of-type {
    border-right: none;
  }

  &[aria-current="page"] {
    background: var(--grey-400);
    color: var(--black);

  }
}