.wrapper {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.wrapperFooter {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.editButton {
  display: flex;
  align-items: center;
  color: #8A89A3;
  font-size: 16px;
  gap: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.registrationDate {
  font-size: 14px;
  color: #6c757d;
}

.profileContainer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}

.logoContainer {
  display: flex;
  align-items: start;
}

.companyLogo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
}

.companyInfo {
  display: flex;
  flex-direction: column;
}

.companyName {
  font-size: 40px;
  font-weight: bold;
}

.deletePhoto {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
}

.createBranchButton {
  background: #fff;
  border: 1px solid #6c757d;
  padding: 5px 10px;
  cursor: pointer;
}

.title {
  margin-bottom: 10px;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.activeTab {
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.profileSection {
  padding: 20px;
  border-radius: 8px;
  label {
    font-size: 12px;
  }
}

.profileSectionFooter {
  padding: 15px;
  border-radius: 8px;
  label {
    font-size: 12px;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  color: #999999
}

.socialsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  min-height: 100px;
}

.footerButtons {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px
}

.deleteCompany {
  text-align: right;
  justify-content: center;
}

.unsavedChanges {
  color: #999999;
  font-size: 14px;
}

.socialsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.socialLink {
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: #000;
}

.addButton {
  margin-top: 10px;
}

.socialLink.filled {
  border: 2px solid #000;
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.input, .textarea {
  width: 100%;
  padding: 8px;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: end;
}


.row > div {
  width: 48%;
}

