// fonts
$f-main: 'Roboto', sans-serif;

// font-sizes
$f-size-9: 9px;
$f-size-11: 11px;
$f-size-12: 12px;
$f-size-14: 14px;
$f-size-16: 16px;
$f-size-32: 32px;
$f-size-24: 24px;
$f-size-40: 40px;

// font-weight
$weight-400: 400;
$weight-700: 700;

// line-height
$line-height-120: 120%;
$line-height-130: 130%;
$line-height-160: 160%;
$line-height-170: 170%;

// colors
$white: #ffffff;
$black: #000000;
$red: #FF295B;
$grey-100: #8A89A3;
$grey-200: #BCBCCC;
$grey-300: #E9E9F2;
$grey-400: #F7F7FA;
$blue: #472CF5;
$blue-light: #6952FF;
$blue-dark: #3A20E6;
$orange: #FF9124;
$green: #09D66F;
$yellow: #FFD91A;
$color-vk: #0077FF;
$background: rgba(0, 0, 0, 0.51);

$colors: (
        white: $white,
        black: $black,
        red: $red,
        grey-100: $grey-100,
        grey-200: $grey-200,
        grey-300: $grey-300,
        grey-400: $grey-400,
        blue: $blue,
        blue-light: $blue-light,
        blue-dark: $blue-dark,
        orange: $orange,
        green: $green,
        yellow: $yellow,
        color-vk: $color-vk,
);
$color-text-bg-pairs: (
        (white black),
        (black white),
        (red white),
        (grey-100 black),
        (grey-200 black),
        (grey-300 black),
        (grey-400 black),
        (blue white),
        (blue-light white),
        (blue-dark white),
        (orange white),
        (green white),
        (yellow black),
        (color-vk white),
);

// border
$border: rgba(55, 49, 245, 0.28);
$border-light: rgba(188, 188, 204, 0.4);
;


$zIndexes: (
        sidebar: 1,
        content: 1111,
        bottombar: 2222,
        modal: 3333,
        dropdown: 4444,
        tom: 999999999,
);
