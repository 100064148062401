@use "@utils/variables" as var;
@use "@utils/classes" as classes;
@use "sass:map";


@include classes.make-helpers();
@include classes.make-static-helpers();

:root {
    @each $pair in var.$color-text-bg-pairs {
        $bgName: nth($pair, 1);
        $textName: nth($pair, 2);
        --#{$bgName}: #{map.get(var.$colors, $bgName)};
        --text-#{$textName}: #{map.get(var.$colors, $textName)};
    }
}

@each $pair in var.$color-text-bg-pairs {
    $bgName: nth($pair, 1);
    $textName: nth($pair, 2);

    .bg-#{$bgName} {
        background-color: var(--#{$bgName});
        color: var(--#{$textName});
    }
    .bg-#{$bgName}-bordered {
        background: transparent;
        color: var(--#{$bgName});
        border: 1px solid currentColor;
    }
}

.click-through {
    pointer-events: none;
}


